import axios from 'axios'
import {
  Message
} from 'element-ui' // 2.0消息提示框
import router from '@/routers'


// 创建实例
const service = axios.create({
  baseURL: "/", // 当前环境地址变量
  timeout: 10000, // 请求超时时间
  headers: { // 设置默认请求头
    'Content-Type': 'application/json;charset=UTF-8'
  },
})

// 请求拦截
service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('yu_token')
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config
  },
  err => {
    return Promise.reject(err);
  }
);


// 响应拦截
service.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    const {
      status,
      statusText
    } = err.response
    switch (status) {
      case 500:
        Message.error("服务器无响应,请检查网络");
        break;
      case 401:
        Message.error("登录已过期,请重新登录");
        router.push('/mine/login')
        break;
      default:
        Message.error(statusText);
    }
    return Promise.reject(err);
  }
);

// 请求函数
function request(method, url, data) {
  data = method === 'get' || method === 'delete' ? {
    params: data
  } : data
  return new Promise((resolve, reject) => {
    service[method](url, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
  })
}
export default request